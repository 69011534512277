@import "./colors.css";

/* general */
.mainSupportBlock .row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.mainSupportBlock{
    overflow-x:hidden !important;
    overflow-y:hidden !important;
}

.ticketAddIcon  {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
}
.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.edit_header_icon_add {
    cursor: pointer;
    margin-right: 15px;
    vertical-align: sub;
    font-size: 1.5rem;
    color: var(--primary--color);
}

/* header */
.supportHeader{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.headerIcons{
    float: right;
    cursor: pointer;
}

.supportListSection {
    height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
    border-radius: 0px;
}

.ticketDescription {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-weight: 500;
    width: 250px;
}

.selectedTicket{
    background-color: #007bff3f;
    cursor: pointer;
    border-radius: 0px;
}
.ticket{
    background-color: white;
    cursor: pointer;
    border-radius: 0px;
}

.ticket:hover{
    background-color: #007bff1a;
}

.ticketOptions>button {
    max-width: 100px;
    min-width: 100px;
}

.selectMsg {
    margin-top: 10rem;
}

.supportDetailSection{
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 0px;
    overflow: hidden;
}

.supportDetails{
    border: 1px solid #d9d9d9;
    border-radius: 0px;
    padding: 8px 24px;
    max-height: 200px;
    min-height: 200px;
}


.clientInfo{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ticketInfo{
    padding-left: 0px !important;
    padding-right: 0px !important;
}


/* chat list */
.messageListContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.message-list {
    border: 1px solid #d9d9d9;
    height: calc(100vh - 510px);
}

.message-list img {
    width: 350px;
}



/* send message input */
.sendMessage {
    border: 1px solid #d9d9d9;
    background-color: white;
    padding: 15px;
    padding-left: 0px !important;
    padding-top: 0px;
    width: 100%;
    height: 130px;
    min-height: 130px;
    max-height: 130px;
}

.sendMessage .rce-input-textarea {
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
}